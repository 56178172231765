/* eslint-disable */
'use client';

import { useAtom } from 'jotai';
// import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';
import { USER_CONSENT_COOKIE_KEY } from '../../env-constants';
// import { pageview } from '../gtagHelper';
import isCookiesAccepted from '../store/cookies';

const GoogleAnalytics = () => {
  // const pathname = usePathname();
  // const searchParams = useSearchParams();

  const [cookiesAccepted, setCookiesAccepted] = useAtom(isCookiesAccepted);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isAccepted = localStorage.getItem(USER_CONSENT_COOKIE_KEY) === '1';
      setCookiesAccepted(isAccepted);
    }
  }, []);

  // useEffect(() => {
  //   const url = pathname + searchParams.toString();
  //   cookiesAccepted && pageview(url);
  // }, [pathname, searchParams]);

  return (
    cookiesAccepted
      ? (
        <>
          <Script
            id='gtag-init'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-MMH3KGX2');
            `,
            }}
          />
          <Script 
            id='gtag-script'
            dangerouslySetInnerHTML={{
              __html: ` 
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-F42ZRNXXBW');
              `
          }}/>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-F42ZRNXXBW"/>
          <noscript
            dangerouslySetInnerHTML={{
              __html: '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MMH3KGX2"\n' +
                'height="0" width="0" style="display:none;visibility:hidden"></iframe>'}}></noscript>
        </>
      ) : null
  );
};

export default GoogleAnalytics;
